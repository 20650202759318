:root {
  --item-width: 25px;
  --item-height: 25px;
}

.react-minimal-datetime-range-calendar--range {
  display: inline-block;
  vertical-align: top;
}

.react-minimal-datetime-range {
  opacity: 0;
  position: relative;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1), 0 0 4px 0 rgba(0, 0, 0, 0.08);
  display: inline-block;
  transition: all 0.3s;
  transform: translateY(-1em) perspective(600px) rotateX(10deg);
  padding: 20px;
  background-color: #fff;
}

.react-minimal-datetime-range.visible {
  z-index: 1;
  opacity: 1;
  transform: translateY(0) perspective(600px) rotateX(0);
}

.react-minimal-datetime-range__calendar {
  display: inline-block;
  vertical-align: top;
  margin-bottom: 40px;
  position: relative;
}

.react-minimal-datetime-range__calendar:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.react-minimal-datetime-range__calendar {
}

.react-minimal-datetime-range__close {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  color: #adb5bd;
}

.react-minimal-datetime-range__clear.disabled {
  cursor: not-allowed;
}

.react-minimal-datetime-range__clear {
  cursor: pointer;
  position: absolute;
  right: 2%;
  top: 50%;
  transform: translateY(-50%);
  color: #adb5bd;
}

@media only screen and (max-width: 900px) {
  .react-minimal-datetime-range-calendar--range {
    display: block;
  }
  .react-minimal-datetime-date-piker__divider {
    display: block;
  }
}

/* dropdown section */

.react-minimal-datetime-range-dropdown {
  position: relative;
}

.react-minimal-datetime-range-dropdown.visible .react-minimal-datetime-range-dropdown-calendar__menu {
  transform: translate3d(-50%, 0, 0) scale3d(1, 1, 1);
  transform: translate(-50%, 0) scale(1, 1) \9;
  opacity: 1;
  padding: 10px;
  z-index: 1000;
}

.react-minimal-datetime-range-dropdown.visible .react-minimal-datetime-range-dropdown-calendar__menu-no-effect {
  display: block;
}

.react-minimal-datetime-range-dropdown .react-minimal-datetime-range-dropdown-calendar__menu {
  will-change: transform, opacity;
  transform: translate3d(-50%, 0, 0) scale3d(1, 0, 1);
  transform: translate(-50%, 0) scale(1, 0) \9;
  opacity: 0;
  left: 50%;
  width: 280px;
  margin-top: 30px;
  text-align: center;
  transform-origin: 0 0;
  transition: transform 0.4s, opacity 0.2s;
  position: absolute;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.3), 0 0 1px 0 rgba(0, 0, 0, 0.12);
  /*z-index: -1;*/
  background-color: #fff;
}

.react-minimal-datetime-range-dropdown .react-minimal-datetime-range-dropdown-calendar__container {
  border-radius: 3px;
  overflow: hidden;
  margin-top: 10px;
}

.react-minimal-datetime-range-dropdown .react-minimal-datetime-range-dropdown-calendar__item {
  padding: 1px 4px;
  line-height: 20px;
  transition: background-color 0.4s;
  cursor: pointer;
  display: block;
}

.react-minimal-datetime-range-dropdown-calendar__month {
  background-color: #fff;
  *zoom: 1;

  &:after {
    content: '\200B';
    display: block;
    height: 0;
    clear: both;
  }
}

.react-minimal-datetime-range-dropdown-calendar__month-item {
  background-color: #fff;
  cursor: pointer;
  float: left;
  width: 33.3%;
}

.react-minimal-datetime-range-dropdown-calendar__month-item > div {
  padding: 10px 0;
  padding: 10px 2px;
  font-size: 12px;
  margin: 5px;
  background-color: #fff;
  transition: all 0.3s;

  &:hover {
    background-color: #74c0fc;
    color: #fff;
  }
}

.react-minimal-datetime-range-dropdown-calendar__month-item.active > div {
  background-color: #339af0;
  color: #fff;
}

.react-minimal-datetime-range-calendar__previous,
.react-minimal-datetime-range-calendar__next {
}

.react-minimal-datetime-range-dropdown .react-minimal-datetime-range-dropdown-calendar__item:hover {
  background-color: #eee;
}

.react-minimal-datetime-range-dropdown-calendar__year {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.react-minimal-datetime-range-dropdown-calendar__year-item {
  background-color: #fff;
  cursor: pointer;
  float: left;
  height: 100%;
  display: table;
  width: 20%;
}

.react-minimal-datetime-range-dropdown-calendar__year-item > span {
  height: 100%;
  display: table-cell;
  vertical-align: middle;
  font-size: 12px;
  margin: 2px;
  font-size: 14px;
  background-color: #fff;
  transition: all 0.3s;

  &:hover {
    background-color: #74c0fc;
    color: #fff;
  }
}

.react-minimal-datetime-range-dropdown-calendar__year-item.active > span {
  background-color: #339af0;
  color: #fff;
}

/* end of dropdown section */

.react-minimal-datetime-range-calendar__default-day,
.react-minimal-datetime-range-calendar__today {
  font-size: 12px;
  margin-top: 10px;
}

.react-minimal-datetime-range-calendar__today {
  left: 0;
}

.react-minimal-datetime-range-calendar__default-day {
  right: 0;
}

.react-minimal-datetime-range-calendar__default-day .react-minimal-datetime-range-calendar__icon,
.react-minimal-datetime-range-calendar__today .react-minimal-datetime-range-calendar__icon {
  font-size: 15px;
}

.react-minimal-datetime-range-calendar__clicker {
  cursor: pointer;
}

.react-minimal-datetime-range__col {
  display: inline-block;
  vertical-align: middle;
}

.react-minimal-datetime-range-calendar__title {
  cursor: pointer;
  width: 100%;
  position: absolute;
  color: var(--oc-gray-8);
  line-height: 17px;

  &:hover {
    color: #74c0fc;
  }
}

.react-minimal-datetime-range-calendar__inline-span span {
  display: inline-block;
  vertical-align: middle;
}

.react-minimal-datetime-range-calendar__inline-span:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.react-minimal-datetime-range-calendar__content {
}

.react-minimal-datetime-range-calendar__icon {
  cursor: pointer;
  font-size: 20px;
}

.react-minimal-datetime-range__col-0-5 {
  width: 5%;
}

.react-minimal-datetime-range__col-9 {
  width: 90%;
}

.react-minimal-datetime-range__col-3 {
  width: 25%;
}

.react-minimal-datetime-range__col-6 {
  width: 50%;
}

.react-minimal-datetime-range-calendar__header {
  text-align: center;
}

.react-minimal-datetime-range--inline-block {
  display: inline-block;
  vertical-align: middle;
}

.react-minimal-datetime-range-calendar__table {
  display: table;
  border-collapse: collapse;
  border-collapse: initial !important\9;
  margin: 0 auto;
}

@media all and (-ms-high-contrast: none) {
  .react-minimal-datetime-range-calendar__table {
    border-collapse: initial;
  }
}

@supports (-ms-ime-align: auto) {
  .react-minimal-datetime-range-calendar__table {
    border-collapse: initial;
  }
}

.react-minimal-datetime-range-calendar__table-row {
  display: table-row;
}

.react-minimal-datetime-range-calendar__table-cel {
  font-size: 12px;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  padding: 10px;
  cursor: default;
  transition: all 0.3s;
  background-color: #fff;
  color: var(--oc-gray-7);
  padding: 6px;
  width: var(--item-width);
  height: var(--item-height);

  &.disabled {
    color: #adb5bd;
  }

  &.today {
    color: #fc7474;
  }
  &.marked {
    position: relative;
    &:after {
      position: absolute;
      content: '';
      width: 5px;
      height: 5px;
      background-color: #ced4da;
      border-radius: 50%;
      left: 50%;
      bottom: 3px;
      transform: translateX(-50%);
    }
  }
  &.active {
    &:not(.today) {
      color: #fff;
      background-color: #74c0fc;
    }

    &.range {
      &.today {
        color: #fff;
        background-color: #74c0fc;
        text-decoration: underline;
      }
    }
  }

  &.highlight {
    background-color: #d0ebff;
  }

  &.no-border {
    border: 1px solid transparent;
  }

  &.react-minimal-datetime-range-calendar__date-item {
    position: relative;

    &:not(.disabled) {
      cursor: pointer;

      &:hover {
        color: #fff;
        background-color: #74c0fc;
      }
    }
  }

  &.react-minimal-datetime-range-calendar__date-item .react-minimal-datetime-range-calendar__icon {
    position: absolute;
    right: 0;
    bottom: 0;
    font-size: 12px;
  }
}

.react-minimal-datetime-range-calendar__table-caption {
  color: var(--oc-gray-7);
}

.react-minimal-datetime-range-calendar__mask {
  opacity: 0;
  filter: alpha(opacity=0);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);

  &.visible {
    opacity: 1 !important;
    filter: alpha(opacity=100);
    background-color: rgba(0, 0, 0, 0.3) !important;
    z-index: 1 !important;
  }
}

.react-minimal-datetime-range-check {
  position: absolute;
  right: 0px;
  bottom: 0px;
  font-size: 12px;
}

.react-minimal-datetime-range__icon-fill {
  fill: var(--oc-gray-8);
}

.today.active .react-minimal-datetime-range-check__path {
  fill: #fc7474;
}

.active .react-minimal-datetime-range-check__path,
.today:hover .react-minimal-datetime-range-check__path {
  fill: #fff;
}

.react-minimal-datetime-range-calendar__button {
  position: absolute;
  bottom: -40px;
  display: inline-block;
  color: var(--oc-gray-6);
  cursor: pointer;
  padding: 5px 10px;
  border: 1px solid #ced4da;
  transition: all 0.3s;
  background-color: #fff;

  &:hover {
    border: 1px solid #4dabf7;
    background-color: #4dabf7;
    color: #fff;
  }
}

.forwardEnter {
  will-change: transform;
  transition: opacity 0.5s ease-in, transform 0.3s;
  opacity: 1;
  transform: translate3d(100%, 0, 0);

  &.forwardEnterActive {
    transform: translate3d(0, 0, 0);
  }
}

.forwardLeave {
  opacity: 1;
  transition: opacity 0.5s ease-in;

  &.forwardLeaveActive {
    opacity: 0;
  }
}

.backwardEnter {
  &.backwardEnterActive {
  }
}

.backwardLeave {
  will-change: transform, opacity;
  transition: transform 0.3s ease-in;
  transform: translate3d(100%, 0, 0);

  &.backwardLeaveActive {
  }
}

.react-minimal-datetime-range-calendar__title-container {
  position: relative;
  display: block;
  height: 18px;
  overflow: hidden;
  width: 100%;
  text-align: center;
}

.react-minimal-datetime-range-calendar__selector-panel-year-set-container {
  position: relative;
  display: block;
  height: 24px;
  overflow: hidden;
  text-align: center;
  width: 100%;
  margin: 0 auto;
}

.react-minimal-datetime-range-calendar__body-container {
  position: relative;
  display: block;
  transition: height 0.3s;
  overflow: hidden;
  text-align: center;
}

.slide {
  position: absolute;
}

.slide-enter {
  transform: translateX(100%);
  transition: 0.3s transform ease-in-out;
}

.slide-enter-active {
  transform: translateX(0);
}

.slide-exit {
  transform: translateX(0);
  transition: 0.3s transform ease-in-out;
}

.slide-exit-active {
  transform: translateX(-100%);
}

.forward-enter {
  transform: translateX(100%);
  transition: 0.3s transform ease-in-out;
}

.forward-enter-active {
  transform: translateX(0);
}

.forward-exit {
  transform: translateX(0);
  transition: 0.3s transform ease-in-out;
}

.forward-exit-active {
  transform: translateX(-100%);
}

.backward-enter {
  transform: translateX(-100%);
  transition: 0.3s transform ease-in-out;
}

.backward-enter-active {
  transform: translateX(0);
}

.backward-exit {
  transform: translateX(0);
  transition: 0.3s transform ease-in-out;
}

.backward-exit-active {
  transform: translateX(100%);
}

.react-minimal-datetime-range__range-input-wrapper {
  width: 100%;
  height: 32px;
  position: relative;
  display: inline-block;
  padding: 4px 0;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  transition: all 0.3s;
}

.react-minimal-datetime-range__range-input-wrapper.disabled {
  border: 1px solid #ccc;
  color: #ccc;
  background: #f8f8f8;
  cursor: not-allowed;
}

.react-minimal-datetime-range__range-input-wrapper input.react-minimal-datetime-range__range-input.disabled {
  cursor: not-allowed;
}

.react-minimal-datetime-range__range-input-wrapper input.react-minimal-datetime-range__range-input {
  border: none;
  width: 44%;
  height: 95%;
  text-align: center;
  background-color: transparent;
  outline: 0;
}

.react-minimal-datetime-range__range-input-wrapper .react-minimal-datetime-range__range-input-separator {
  display: inline-block;
  min-width: 10px;
  white-space: nowrap;
  text-align: center;
  pointer-events: none;
  vertical-align: middle;
}

.react-minimal-datetime-range__range .react-minimal-datetime-range {
  position: absolute;
}

.react-minimal-datetime-range__button-wrapper {
  text-align: right;
}

.react-minimal-datetime-range__button {
  font-size: 12px;
  cursor: pointer;
  display: inline-block;
  margin-right: 10px;
  padding: 2px 5px;
  border-radius: 4px;
}

.react-minimal-datetime-range__button--type {
  color: #74c0fc;

  &.disabled {
    color: #adb5bd;
    cursor: not-allowed;
  }
}

.react-minimal-datetime-range__button--confirm {
  background-color: #74c0fc;
  color: #fff;
  border: 1px solid #4dabf7;

  &.disabled {
    background-color: #f1f3f5;
    color: #adb5bd;
    cursor: not-allowed;
    border: 1px solid #ced4da;
  }
}

.react-minimal-datetime-date-piker {
  position: relative;
}

.react-minimal-datetime-date-piker__divider {
  display: inline-block;
  width: 20px;
}

.react-minimal-datetime-range__time-piker {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #fff;
}

.react-minimal-datetime-range__time-select-wrapper {
  height: 100%;
}

.react-minimal-datetime-range__date {
  display: inline-block;
  width: 50%;
  text-align: center;
  margin-bottom: 10px;
}

.react-minimal-datetime-range__time-select-options-wrapper {
  overflow-y: auto;
  height: 85%;
  display: inline-block;
  width: 25%;
}

.react-minimal-datetime-range__time-select-wrapper--single {
  text-align: center;
}

.react-minimal-datetime-range__time-select-wrapper--single .react-minimal-datetime-range__time-select-options-wrapper {
  width: 50%;
}

.react-minimal-datetime-range__time-select-option {
  padding: 2 5px;
  cursor: pointer;

  &:hover {
    background-color: #d0ebff;
  }

  &.active {
    color: #fff;
    background-color: #74c0fc;
  }
}
